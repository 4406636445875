import {
  signInWithEmailAndPassword,
  signOut,
  Auth,
  GoogleAuthProvider,
  signInWithRedirect,
  connectAuthEmulator,
  setPersistence,
  UserCredential,
  getAuth,
  browserLocalPersistence,
  signInWithCustomToken,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import { ConfigService } from './configService';
import { ApiService } from './apiService';
import { RedirectService } from './redirectService';
import { getConfig } from '../config';
import { LoginToAGWResponse } from '../types/common';
import { ToastService } from './toastService';
import { Logger } from '../utils/logger';
import { isTopApp } from '../utils/isTopApp';
import { CustomTokenResponse } from '../types/CustomTokenResponse';
import { AuthError } from '../errors/AuthError';
import { MessageService } from './messageService';

export class AuthService {
  private static instance: AuthService;
  private readonly apiService: ApiService;
  private readonly configService: ConfigService;
  private readonly redirectService: RedirectService;
  private readonly toastService: ToastService;
  private readonly useEmulator: boolean = JSON.parse(
    getConfig().FIREBASE_USE_AUTH_EMULATOR,
  );
  private messageService!: MessageService;
  private auth?: Auth;
  private emulatorConnected: boolean = false;
  private prevIdToken: string | null = null;

  public readonly initialized: Promise<boolean>;
  public loggedUser: LoginToAGWResponse | null = null;

  private constructor() {
    this.configService = ConfigService.getInstance();
    this.apiService = ApiService.getInstance();
    this.redirectService = RedirectService.getInstance();
    this.toastService = ToastService.getInstance();
    this.initialized = this.initializeFirebase();
  }

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
      MessageService.initialize(AuthService.instance);
      AuthService.instance.messageService = MessageService.getInstance();
    }

    return AuthService.instance;
  }

  private get googleAuthProvider(): GoogleAuthProvider {
    return new GoogleAuthProvider();
  }

  get firebaseAuth(): Auth {
    if (!this.auth) {
      throw new AuthError('Firebase Auth not initialized');
    }

    return this.auth;
  }

  public async handleLoginResponse(loginResult: UserCredential): Promise<void> {
    try {
      const idToken = await loginResult.user.getIdToken();
      await this.apiService.createCustomToken(idToken);

      this.loggedUser = await this.apiService.loginToAGW(idToken);
      await this.verifyPermissions(this.loggedUser);

      if (isTopApp()) {
        this.redirectService.redirectToApp();
      } else {
        Logger.logDebug(
          'Handle login called in iframe. Redirect action is skipped.',
        );
      }
    } catch (error) {
      await this.handleError(error, { logoutOnError: true });
    }
  }

  public async loginWithPassword(
    email: string,
    password: string,
  ): Promise<void> {
    await this.ensureInitialized();
    try {
      const loginResult = await signInWithEmailAndPassword(
        this.firebaseAuth,
        email,
        password,
      );
      await this.handleLoginResponse(loginResult);
    } catch (error) {
      await this.handleError(error, {
        notificationMessage: 'Invalid email or password',
      });
    }
  }

  public async loginWithGoogle(): Promise<void> {
    await this.ensureInitialized();
    await setPersistence(this.firebaseAuth, browserLocalPersistence);
    await signInWithRedirect(this.firebaseAuth, this.googleAuthProvider);
  }

  public async loginWithCustomToken(token: string): Promise<void> {
    await this.ensureInitialized();
    await setPersistence(this.firebaseAuth, browserLocalPersistence);
    await signInWithCustomToken(this.firebaseAuth, token);
  }

  public async getCustomToken(): Promise<CustomTokenResponse> {
    return this.apiService.getCustomToken();
  }

  public async getIdToken(
    forceRefresh: boolean = false,
  ): Promise<string | null> {
    const idToken =
      (await this.firebaseAuth.currentUser?.getIdToken(forceRefresh)) ?? null;
    if (idToken !== this.prevIdToken) {
      Logger.logDebug('Id token updated');
      this.prevIdToken = idToken;
    }
    return idToken;
  }

  public async refreshAGWLogin(idToken: string): Promise<void> {
    try {
      this.loggedUser = await this.apiService.loginToAGW(idToken);
    } catch (error) {
      await this.handleError(error, { logoutOnError: true });
    }
  }

  /**
   * Logs out the currently signed-in user from the application and Firebase authentication.
   *
   * @param {boolean} [eventFromExternalSource] - Optional flag indicating whether the logout event was triggered from an external source.
   * @return {Promise<void>} A promise that resolves when the logout process completes.
   */
  public async logout(eventFromExternalSource?: boolean): Promise<void> {
    await this.ensureInitialized();
    this.loggedUser = null;
    await signOut(this.firebaseAuth);
    if (eventFromExternalSource) {
      this.messageService.sendLogoutMessage();
    }
    Logger.logInfo('Signed out successfully', this.firebaseAuth.currentUser);
  }

  private async initializeFirebase(): Promise<boolean> {
    try {
      const config = await this.configService.getConfig();

      initializeApp(config.firebase);
      this.auth = getAuth();
      this.auth.tenantId = config.firebase.tenantId;

      if (this.useEmulator && !this.emulatorConnected) {
        connectAuthEmulator(this.auth, getConfig().FIREBASE_EMULATOR, {
          disableWarnings: true,
        });
        this.emulatorConnected = true;
      }

      await this.getIdToken(true);
      return true;
    } catch (error) {
      await this.handleError(error);
      return false;
    }
  }

  private async handleError(
    error: unknown,
    {
      notificationMessage,
      logoutOnError,
    }: {
      notificationMessage?: string;
      logoutOnError?: boolean;
    } = {},
  ): Promise<void> {
    Logger.logError(error);
    const message = error instanceof Error ? error.message : String(error);
    this.toastService.showErrorToast(notificationMessage || message);
    if (logoutOnError) {
      await this.logout();
    }
    throw new AuthError(message, error);
  }

  private async verifyPermissions(
    agwResponse: LoginToAGWResponse,
  ): Promise<void> {
    if (!agwResponse.companyPermissions) {
      await this.logout();
      throw new AuthError('Company permissions not found');
    }

    const companyPermissions = agwResponse.companyPermissions.find(
      (cp) => cp.code === this.configService.getOrganizationCode(),
    );

    if (!companyPermissions) {
      await this.logout();
      throw new AuthError(
        `No permissions found for organization code "${this.configService.getOrganizationCode()}"`,
      );
    }

    const appPermission = companyPermissions.permissions.find(
      (permission) =>
        permission.name ===
        `${this.configService.getActivePermissionPrefix()}_APP`,
    );

    if (!appPermission) {
      await this.logout();
      throw new AuthError(
        `No permissions found for subject "${this.configService.getActivePermissionPrefix()}_APP"`,
      );
    }

    const hasLoginPermission = appPermission.actions.some(
      (action) => action.name === this.configService.loginPermission,
    );

    if (!hasLoginPermission) {
      await this.logout();
      throw new AuthError(
        `No permissions found for action "${this.configService.loginPermission}" in subject "${this.configService.getActivePermissionPrefix()}_APP"`,
      );
    }
  }

  private async ensureInitialized(): Promise<void> {
    const initialized = await this.initialized;
    if (!initialized) {
      throw new AuthError('Firebase not initialized');
    }
  }
}
