import { isTopApp } from './isTopApp.ts';

export class Logger {
  private static getMessage(args: unknown[]): unknown[] {
    const mode = isTopApp() ? 'APP' : 'IFRAME';
    return [`🔒[ULA][${mode}]`, ...args];
  }

  static logInfo(...args: unknown[]) {
    console.log(...this.getMessage(args));
  }

  static logError(...args: unknown[]) {
    console.error(...this.getMessage(args));
  }

  static logWarn(...args: unknown[]) {
    console.warn(...this.getMessage(args));
  }

  static logDebug(...args: unknown[]) {
    console.debug(...this.getMessage(args));
  }
}
