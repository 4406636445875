import 'simple-notify/dist/simple-notify.css';
import { ConfigService } from './configService';
import { Logger } from '../utils/logger';
import { MessageError } from '../errors/MessageError';
import type { AuthService } from './authService';
import { LoginToAGWResponse } from '../types/common';

const OUTPUT_MESSAGE_TYPES = {
  USER_IDENTITY: 'ula-user-identity',
  ID_TOKEN: 'ula-id-token',
  LOGOUT: 'ula-logout',
} as const;

const INPUT_MESSAGE_TYPES = {
  GET_USER_INFO: 'getUserInfo',
  GET_ID_TOKEN: 'getIdToken',
  LOGOUT: 'logout',
} as const;

type OutputMessageTypes =
  (typeof OUTPUT_MESSAGE_TYPES)[keyof typeof OUTPUT_MESSAGE_TYPES];
type InputMessageTypes =
  (typeof INPUT_MESSAGE_TYPES)[keyof typeof INPUT_MESSAGE_TYPES];

interface UserIdentityMessage {
  user: unknown;
  idToken: string | null;
  name: OutputMessageTypes;
}

export class MessageService {
  private static instance: MessageService;
  private readonly configService: ConfigService;

  private constructor(private readonly authService: AuthService) {
    this.configService = ConfigService.getInstance();
  }

  public static initialize(authService: AuthService): void {
    if (!MessageService.instance) {
      MessageService.instance = new MessageService(authService);
    }
  }

  public static getInstance(): MessageService {
    if (!MessageService.instance) {
      throw new MessageError(
        'MessageService must be initialized with authService first',
      );
    }
    return MessageService.instance;
  }

  public sendLogoutMessage(): void {
    const message = this.getMessagePayload({
      name: OUTPUT_MESSAGE_TYPES.LOGOUT,
    });
    this.configService.getAllowedOrigins().forEach((origin) => {
      this.postMessageToParent(message, origin);
    });
  }

  public sendUserInfo(): void {
    try {
      Logger.logInfo('Sending user info');
      const message = this.createUserIdentityMessage();

      this.configService.getAllowedOrigins().forEach((origin) => {
        this.postMessageToParent(message, origin);
      });
    } catch (error) {
      throw new MessageError('Failed to send user info', error);
    }
  }

  public startListening(): void {
    window.onmessage = this.handleIncomingMessage.bind(this);
    Logger.logDebug('Message listener started');
  }

  public stopListening(): void {
    window.onmessage = null;
    Logger.logDebug('Message listener stopped');
  }

  public async sendIdToken(origin: string): Promise<void> {
    const message = await this.createIdTokenMessage();
    this.postMessageToParent(message, origin);
  }

  private getMessagePayload({
    idToken,
    user,
    name,
  }: {
    idToken?: string | null;
    user?: LoginToAGWResponse | null;
    name: OutputMessageTypes;
  }): UserIdentityMessage {
    return {
      name,
      idToken: idToken ?? null,
      user: user ?? null,
    };
  }

  private async createIdTokenMessage(): Promise<UserIdentityMessage> {
    const idToken = await this.authService.getIdToken();
    return this.getMessagePayload({
      idToken,
      name: OUTPUT_MESSAGE_TYPES.ID_TOKEN,
    });
  }

  private createUserIdentityMessage(): UserIdentityMessage {
    const user = this.authService.loggedUser;
    return this.getMessagePayload({
      user,
      name: user
        ? OUTPUT_MESSAGE_TYPES.USER_IDENTITY
        : OUTPUT_MESSAGE_TYPES.LOGOUT,
    });
  }

  private validateOrigin(origin: string): boolean {
    return this.configService.getAllowedOrigins().includes(origin);
  }

  private postMessageToParent(
    message: UserIdentityMessage,
    origin: string,
  ): void {
    try {
      if (!this.validateOrigin(origin)) {
        throw new MessageError(`Invalid origin: ${origin}`);
      }

      window.parent.postMessage(message, origin);
    } catch (error) {
      throw new MessageError('Failed to post message to parent', error);
    }
  }

  private async handleIncomingMessage(event: MessageEvent): Promise<void> {
    try {
      if (!this.validateOrigin(event.origin)) {
        return;
      }

      switch (event.data as InputMessageTypes) {
        case INPUT_MESSAGE_TYPES.LOGOUT:
          await this.authService.logout(true);
          break;
        case INPUT_MESSAGE_TYPES.GET_USER_INFO:
          this.sendUserInfo();
          break;
        case INPUT_MESSAGE_TYPES.GET_ID_TOKEN:
          await this.sendIdToken(event.origin);
          break;
        default:
          Logger.logWarn(`Ignoring unknown message type: ${event.data}`);
      }
    } catch (error) {
      throw new MessageError('Failed to handle incoming message', error);
    }
  }
}
